/* This example requires Tailwind CSS v2.0+ */

import React from 'react'

import { Link } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Card } from './Card';

export const MainGrid = ({ id, projects }) => {

	console.log(projects);

	return (
		<section id={id} className="bg-kinzica-dark p-8 py-48 lg:px-16 mx-auto w-full ">
			<div className="mx-auto text-center">
				<div className="grid gap-1 sm:grid-cols-2 lg:grid-cols-3">
					{projects.map(p => (
						<Link to={"/works/" + p.frontmatter.tag + "/"+ p.frontmatter.slug} key={p.id}>
              <Card firstImage={getImage(p.frontmatter.thumb.childImageSharp.gatsbyImageData)} title={p.frontmatter.title}/>
						</Link>
					))}
				</div>
			</div>
		</section>

	)
}
